import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5f9cabc6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "main"
};
var _hoisted_2 = {
  class: "main-title"
};
var _hoisted_3 = {
  class: "date"
};
var _hoisted_4 = {
  class: "sub"
};
var _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.dayjs(_ctx.data.create_time).format('YYYY-MM-DD HH:mm:ss')), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.data.sub_title), 1), _createElementVNode("div", {
    innerHTML: _ctx.data.content,
    class: "content"
  }, null, 8, _hoisted_5)]);
}