import { defineComponent, onMounted, ref } from 'vue';
import axios from "axios";
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
export default defineComponent({
  name: 'newsid',
  setup: function setup() {
    var data = ref({});
    var title = ref();
    var router = useRouter();
    onMounted(function () {
      var news_id = router.currentRoute.value.query.news_id;
      axios.get("https://picbook.bcepd.cn/d-bcepd/cmpcms/userfree/load?news_id=".concat(news_id)).then(function (res) {
        console.log(res.data.r);
        data.value = res.data.r;
        var news_title = res.data.r.news_title;
        title.value = news_title;
      });
      window.scrollTo(0, 0);
      console.log(data.value);
    });
    return {
      data: data,
      title: title,
      dayjs: dayjs
    };
  }
});